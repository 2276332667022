import React from "react";
import Paper from "@material-ui/core/Paper";
import PaperAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MonetizationIcon from "@material-ui/icons/MonetizationOn";
import RegisterIcon from "@material-ui/icons/AddCircle";
import BookIcon from "@material-ui/icons/Book";
import AccountIcon from "@material-ui/icons/AccountBalance";
import "./styles.css";

import {
  data_content_head_text,
  data_content_body_text,
  data_foot_cards,
  data_accordian_sol,
} from "./data";

const Content = () => {
  return (
    <div className="content-container">
      <div className="content-head-container">
        <div className="content-head-text">
          <h2>{data_content_head_text.heading}</h2>
          <p>{data_content_head_text.body1}</p>
          <p>{data_content_head_text.body2}</p>
        </div>
        <div className="content-head-video-container">
          <div className="content-head-video">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/E6LMkRbIi-g"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="video"
            />
          </div>
        </div>
      </div>
      <div className="content-body">
        <div className="content-body-text">
          <h2>{data_content_body_text.heading}</h2>
          <p>{data_content_body_text.body1}</p>
          <p>{data_content_body_text.body2}</p>
        </div>
      </div>
      <div className="content-foot">
        <div className="content-foot-cards">
          <Card id={0} title={data_foot_cards[0]} />
          <Card id={1} title={data_foot_cards[1]} />
          <Card id={2} title={data_foot_cards[2]} />
          <Card id={3} title={data_foot_cards[3]} />
        </div>
        {/* <div className="content-foot-accordions">
          <Accordion question="What questions should I ask before hiring a Chartered Accountant for my business?" />
          <Accordion question="How can online CA services provided by CAonWeb help in company registration?" />
          <Accordion question="How can I choose a reputed Chartered Accountant?" />
          <Accordion question="Which is the best income tax consultancy service provider?" />
          <Accordion question="What are the online CA services provided by CAONWEB?" />
          <Accordion question="Where would you find the best Chartered accountants in India?" />
        </div> */}
      </div>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const Accordion = ({ question }) => {
  const Icon = <ExpandMoreIcon />;
  return (
    <div className="content-foot-accordion">
      <PaperAccordion>
        <AccordionSummary
          expandIcon={Icon}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: "#eeeeee" }}
        >
          <Typography style={{ fontWeight: "bold" }}>{question}</Typography>
        </AccordionSummary>
        <AccordianSol />
      </PaperAccordion>
    </div>
  );
};

const AccordianSol = () => {
  return (
    <AccordionDetails>
      <Typography>{data_accordian_sol}</Typography>
    </AccordionDetails>
  );
};

const Card = ({ id, title }) => {
  const style = { color: "#03a9f4", fontSize: 100 };
  return (
    <Paper className="content-foot-card" elevation={3}>
      {id === 0 ? <BookIcon style={style} /> : null}
      {id === 1 ? <MonetizationIcon style={style} /> : null}
      {id === 2 ? <AccountIcon style={style} /> : null}
      {id === 3 ? <RegisterIcon style={style} /> : null}
      <p>{title}</p>
    </Paper>
  );
};

export default Content;
