import React, { useState, useCallback } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import urls from "../../constants/url.js";
import "./styles.css";

const Login = ({ click }) => {
  const [pass, setPass] = useState("");
  const [text, setText] = useState("Login");
  const submitQuery = useCallback(
    async (token) => {
      const result = await query(token);
      if (result === "Success") click(token);
      else setText(result);
    },
    [setText, click]
  );

  return (
    <div className="admin-container">
      <Paper elevation={3} className="admin-login-wrapper">
        <h1 style={{ fontFamily: '"Krona One", sans-serif' }}>Admin Panel</h1>
        <TextField
          label="Password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          type="password"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "20px" }}
          onClick={() => submitQuery(pass)}
        >
          {text}
        </Button>
      </Paper>
    </div>
  );
};

const query = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(urls.admin, { token })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) resolve(err.response.data);
        resolve("Network Error");
      });
  });
};

export default Login;
