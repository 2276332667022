import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./styles.css";
import Home from "./containers/home";
import Services from "./containers/services";
import Team from "./containers/team";
import Contact from "./containers/contact";
import Admin from "./containers/admin";

const App = () => {
  return (
    <HashRouter>
      <div className="root-app">
        <Switch>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/services/:serviceId">
            <Services />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/services">
            <Redirect to="/services/0" />
          </Route>
          <Route path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
};

export default App;
