import React, { useEffect, useState } from "react";
import MonetizationIcon from "@material-ui/icons/MonetizationOn";
import TrademarkIcon from "@material-ui/icons/Polymer";
import BookIcon from "@material-ui/icons/Book";
import AccountIcon from "@material-ui/icons/AccountBalance";
import FssaiIcon from "@material-ui/icons/ImportContacts";
import GstIcon from "@material-ui/icons/Money";
import Banner0Pic from "../../kpg-accountants-assets/kpg-accountants-slide-show/kpg-accountants-banner0.jpg";
import Banner1Pic from "../../kpg-accountants-assets/kpg-accountants-slide-show/kpg-accountants-banner1.jpg";
import Banner2Pic from "../../kpg-accountants-assets/kpg-accountants-slide-show/kpg-accountants-banner2.jpg";
import Banner3Pic from "../../kpg-accountants-assets/kpg-accountants-slide-show/kpg-accountants-banner3.jpg";
import "./styles.css";
import { Link } from "react-router-dom";

const pics = [Banner3Pic, Banner0Pic, Banner1Pic, Banner2Pic, Banner3Pic];
const Slide = ({ id, pic }) => (
  <img
    src={pic}
    className="sd-pic"
    alt="kpg-accountants-slide"
    title="kpg-accountants-slide"
  />
);

const Banner = () => {
  const [id, setId] = useState(0);
  useEffect(() => {
    const ref = setInterval(() => setId((s) => (s + 1) % 5), 4000);
    return () => clearInterval(ref);
  }, [setId]);

  return (
    <div className="banner-content-container">
      <div className="banner-image-container">
        <div
          className="banner-image-slider"
          style={{
            transform: `translateX(-${id * 100}vw) `,
            transition: `transform ${
              id ? 2000 : 0
            }ms cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
          }}
        >
          {pics.map((pic, index) => (
            <Slide key={"slide-pic" + index} pic={pic} />
          ))}
        </div>
      </div>
      <div className="banner-services">
        <h1 className="banner-services-h1">Delivering Solutions.</h1>
        <div className="banner-services-list">
          <ListItem id={0} label="Accounts & Bookkeeping" />
          <ListItem id={1} label="VAT Consultancy" />
          <ListItem id={2} label="Business Setup" />
          <ListItem id={3} label="Internal Audit" />
          <ListItem id={4} label="Payroll Management" />
          <ListItem id={5} label="Statutory Audit" />
        </div>
      </div>
      <div className="banner-form"></div>
    </div>
  );
};

const Icons = {
  0: <MonetizationIcon style={{ color: "#ffffff" }} />,
  1: <GstIcon style={{ color: "#ffffff" }} />,
  2: <BookIcon style={{ color: "#ffffff" }} />,
  3: <AccountIcon style={{ color: "#ffffff" }} />,
  4: <FssaiIcon style={{ color: "#ffffff" }} />,
  5: <TrademarkIcon style={{ color: "#ffffff" }} />,
};

const ListItem = ({ id, label }) => {
  return (
    <div className="banner-services-list-item">
      {Icons[id]}
      <Link to={`/services/${id}`} style={{ textDecoration: "none" }}>
        <p className="banner-services-list-item-text">{label}</p>
      </Link>
    </div>
  );
};

export default Banner;
