import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import Modal from "@material-ui/core/Modal";
import MessageIcon from "@material-ui/icons/Comment";
import BackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import emailValidator from "email-validator";
import axios from "axios";
import urls from "../../constants/url.js";
import "./styles.css";

const FloatingContact = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="floating-nav-button-container">
      <Modal open={open} onClose={() => setOpen(!open)}>
        <React.Fragment>
          <Form click={setOpen} />
        </React.Fragment>
      </Modal>
      <Fab
        size="small"
        aria-label="add"
        style={{
          padding: "15px",
          backgroundColor: "#388e3c",
          color: "#ffffff",
          boxShadow: "none",
        }}
        onClick={() => setOpen(true)}
      >
        <MessageIcon />
      </Fab>
    </div>
  );
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      subject: "",
      content: "",
      mobile: "",
      text: "Submit",
    };
    this.loading = false;
    this.click = this.props.click;
    this.submitQuery = this.submitQuery.bind(this);
  }

  async submitQuery(email, mobile, subject, content) {
    if (!this.loading) {
      this.loading = true;
      const result = await postQuery(email, mobile, subject, content);
      this.setState({ text: result });
      this.loading = false;
    }
  }

  render() {
    return (
      <div className="floating-form">
        <div className="floating-form-back">
          <IconButton onClick={() => this.click(false)}>
            <BackIcon style={{ color: "#ffffff" }} />
          </IconButton>
          <h3
            style={{
              fontFamily: '"Krona One", sans-serif',
              color: "#ffffff",
              padding: "10px 5px 0px 0px",
            }}
          >
            Enquire And we'll be in touch.
          </h3>
        </div>
        <TextField
          className="floating-contact-input-fields"
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          label="Email"
          variant="outlined"
          style={{ margin: "10px" }}
        />
        <TextField
          className="floating-contact-input-fields"
          value={this.state.mobile}
          onChange={(e) => this.setState({ mobile: e.target.value })}
          label="Mobile"
          variant="outlined"
          style={{ margin: "10px" }}
        />
        <TextField
          className="floating-contact-input-fields"
          value={this.state.subject}
          onChange={(e) => this.setState({ subject: e.target.value })}
          label="Subject"
          variant="outlined"
          style={{ margin: "10px" }}
        />
        <TextField
          className="floating-contact-input-fields"
          value={this.state.content}
          onChange={(e) => this.setState({ content: e.target.value })}
          label="Content"
          variant="outlined"
          multiline
          rows={4}
          inputProps={{ maxLength: 254 }}
          style={{ margin: "10px" }}
        />
        <Button
          variant="contained"
          style={{
            margin: "20px",
            backgroundColor: "#388e3c",
            color: "#ffffff",
          }}
          onClick={() =>
            this.submitQuery(
              this.state.email,
              this.state.mobile,
              this.state.subject,
              this.state.content
            )
          }
        >
          {this.state.text}
        </Button>
      </div>
    );
  }
}

const postQuery = (email, mobile, subject, content) => {
  return new Promise((resolve, reject) => {
    if (!emailValidator.validate(email)) return resolve("Invalid Email");
    if (subject.length < 1) return resolve("Enter Subject");
    if (content.length < 1) return resolve("Enter Content");
    if (!/^\d{10}$/.test(mobile)) return resolve("Invalid Mobile");
    axios
      .post(urls.query, { email, mobile, subject, content })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) resolve(err.response.data);
        resolve("Network Error");
      });
  });
};

export default FloatingContact;
