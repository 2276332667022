import React, { useEffect } from "react";
import FloatingContact from "../components/floating-contact/contact";
import InfoBarComponent from "../components/info-bar/info_bar";
import AppBarComponent from "../components/app-bar/app_bar";
import TeamComponent from "../components/team/team";
// import HelpBannerComponent from "../components/help-banner/banner";
import FooterLinksComponent from "../components/footer/footer";
import DevBannerComponent from "../components/dev-banner/banner";

const Team = () => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <FloatingContact />
      <InfoBarComponent />
      <AppBarComponent />
      <TeamComponent />
      <FooterLinksComponent />
      <DevBannerComponent />
    </div>
  );
};

export default Team;
