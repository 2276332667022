import React from "react";

const Liquidation = () => {
  return (
    <div>
      <h1>Payroll management</h1>
      <p>
        Any business that has more than one employee must have a payroll system.
        Paying employees consistently and with no delays not only impacts their
        morale, but it also reflects the company’s financial stability.
        Moreover, it is a requirement for compliance with federal and state
        laws. While this is a complex process, having an efficient system helps
        streamline and centralize the payroll method. But first, let’s discuss
        what is payroll management process?
      </p>
      <p>
        In essence, the payroll management process refers to the administration
        of an employee’s financial records which includes the salaries, wages,
        bonuses, deductions, and net pay. A payroll management system also
        promotes better security and helps keep employee data confidential.
        Different levels of data access can be specified per user roles, from
        administrators to employees.
      </p>
      <p>
        Payroll Outsourcing, as the name implies, involves companies handing off
        their data to organizations that specialize in computing employee
        salaries. KPG Accountants  can be your partner in maintenance, updation
        and processing the payrolls by complying with the local regulations.
        Outsourcing of your payroll processing to KPG Accountants can help you in
        maintaining the confidentiality of the data.
      </p>
    </div>
  );
};

export default Liquidation;
