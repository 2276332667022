export const data_footer_about = {
  summary:
    "KPG Accountants & Tax Consultants is a U.A.E. registered accounting firm providing cost-effective value-added solutions to meet all your business needs. From Accounting Services to Tax Consultation, we offer a wide variety of services to clients.",
  call: "+971-567100237",
  address:
    "Office No. 15, 25th Floor, Prime Tower, Bussiness Bay, Dubai, UAE",
  email: "karan.gupta@kpgaccountants.com",
};

export const data_footer_links = {
  0: {
    title: "Services",
    links: [
      { label: "Accounts & Bookkeeping", link: "/services/0" },
      { label: "VAT Consultancy", link: "/services/1" },
      { label: "Business Setup", link: "/services/2" },
      { label: "Internal Audit", link: "/services/3" },
      { label: "Payroll Management", link: "/services/4" },
      { label: "Statutory Audit", link: "/services/5" },
    ],
  },
  1: {
    title: "Quick Links",
    links: [
      { label: "DFM", link: "https://www.dfm.ae/" },
      {
        label: "Ministry of Finance",
        link: "https://www.mof.gov.ae/en/Pages/default.aspx",
      },
      {
        label: "Central Bank",
        link: "https://www.centralbank.ae/en",
      },
      {
        label: "Federal Finance",
        link:
          "https://u.ae/en/information-and-services/finance-and-investment/federal-finance",
      },
      {
        label: "Economic Times",
        link: "https://economictimes.indiatimes.com/",
      },
      { label: "Financial Times", link: "https://www.ft.com/" },
    ],
  },
};
