import React from "react";

const Accounting = () => {
  return (
    <div>
      <h1>Accounts and Bookkeeping</h1>
      <p>
        KPG Accountants  offers a wide range of accounting services that provides
        solutions to the accounting requirements of any business in the global
        market. We ensure that the financial statements are prepared in line
        with International Financial Reporting Standards (IFRSs). KPG Accountants 
        makes sure that the services are provided with high quality within
        agreed time lines.
      </p>
      <p>This vertical provides the following services:</p>
      <ul>
        <li>Financial accounting system design & implementation</li>
        <li>Setting up of accounting procedures</li>
        <li>Accounting of routine financial transactions</li>
        <li>Accounting of period ending financial and related transactions</li>
        <li>
          Preparation of financial statements under IFRS and other supporting
          schedules
        </li>
        <li>Supervision and review of financial accounting records</li>
      </ul>
    </div>
  );
};

export default Accounting;
