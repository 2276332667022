const dev = {
  query: "http://localhost:5000/query",
  admin: "http://localhost:5000/admin",
  active: "http://localhost:5000/active",
  inactive: "http://localhost:5000/inactive",
  all: "http://localhost:5000/all",
  mark: "http://localhost:5000/mark",
};

const prod = {
  query: "https://kpgaccountants.herokuapp.com/query",
  admin: "https://kpgaccountants.herokuapp.com/admin",
  active: "https://kpgaccountants.herokuapp.com/active",
  inactive: "https://kpgaccountants.herokuapp.com/inactive",
  all: "https://kpgaccountants.herokuapp.com/all",
  mark: "https://kpgaccountants.herokuapp.com/mark",
};

const config = process.env.NODE_ENV === "production" ? prod : dev;
export default config;
