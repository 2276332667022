import React from "react";

const Auditing = () => {
  return (
    <div>
      <h1>Statutory Audit</h1>
      <p>
        Most entities registered in the United Arab Emirates are required by law
        to have their annual financial statements audited.
      </p>
      <p>
        Even though some businesses don’t need to have an audit by law, it makes
        commercial sense for having an audit, such as getting the best price
        when it is opportune time to sell the business, keeping banks happy when
        you need finance, keeping credit insurers and suppliers happy, but most
        of all, giving you the comfort of knowing that you have strong controls
        and accurate results.
      </p>
      <p>
        The companies in UAE require their audits to be completed in line with
        International Standards of Auditing with accounts being prepared under
        International Financial Reporting Standards. We work with our clients to
        ensure that they are able to fulfil their statutory obligations and file
        audited accounts in a timely manner.
      </p>
      <p>
        For many private companies, financial reporting requirements do not
        necessarily require audit services. We can provide alternative services,
        such as reviews and compilations. These types of services may be a
        better alternative to an audit, yet meet your needs and those of your
        financial statement users. We can advise you on what the best
        alternative may be to meet your reporting needs and requirements.
      </p>
    </div>
  );
};

export default Auditing;
