import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FloatingContact from "../components/floating-contact/contact";
import InfoBarComponent from "../components/info-bar/info_bar";
import AppBarComponent from "../components/app-bar/app_bar";
import ServicesComponent from "../components/services/services";
// import HelpBannerComponent from "../components/help-banner/banner";
import FooterLinksComponent from "../components/footer/footer";
import DevBannerComponent from "../components/dev-banner/banner";

const Services = () => {
  useEffect(() => {
    if (window.innerWidth <= 767) window.scrollTo(0, 0);
  });
  const { serviceId } = useParams();
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <FloatingContact />
      <InfoBarComponent />
      <AppBarComponent />
      <ServicesComponent id={serviceId} />
      <FooterLinksComponent />
      <DevBannerComponent />
    </div>
  );
};

export default Services;
