import React from "react";

const Company = () => {
  return (
    <div>
      <h1>Business Setup/ Company Incorporation Services</h1>
      <p>
        UAE has successfully emerged as a global investment and tourism hub over
        the past few decades and it is undoubtedly the dream of any businessman
        to start their company in this futuristic country. It is one of the best
        places in the world to flourish your business as it offers countless
        opportunities to foreign investors, companies and entrepreneurs. Prior
        to company formation in UAE, you should have a thorough knowledge of the
        UAE market and an understanding of the initial costs of setting up a
        business.
      </p>
      <p>
        Forming a company in UAE can turn out to be nightmare if you are unaware
        of the legal formalities. It requires approvals from various government
        departments and a single mistake will result in a re-submission of the
        form along with financial loss.
      </p>
      <p>
        UAE offers 3 types of company formations, which are mainly free zone,
        mainland and offshore. The rules and procedures for setting up a firm
        are different for all these establishments.
      </p>
      <p>This vertical provides the following services:</p>
      <ul>
        <li>Offshore Company Formation</li>
        <li>Tax Residency (Domicile) Certificate</li>
        <li>Liquidation/De-registration</li>
        <li>Free Zone Company Formation</li>
        <li>Mainland Company Incorporation</li>
      </ul>
    </div>
  );
};

export default Company;
