import React, { useEffect } from "react";
import InfoBarComponent from "../components/info-bar/info_bar";
import AppBarComponent from "../components/app-bar/app_bar";
import ContactComponent from "../components/contact/contact";
// import HelpBannerComponent from "../components/help-banner/banner";
import FooterLinksComponent from "../components/footer/footer";
import DevBannerComponent from "../components/dev-banner/banner";

const Contact = () => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <InfoBarComponent />
      <AppBarComponent />
      <ContactComponent />
      <FooterLinksComponent />
      <DevBannerComponent />
    </div>
  );
};

export default Contact;
