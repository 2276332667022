import React, { useState, useCallback, useRef } from "react";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import LocationIcon from "@material-ui/icons/LocationOn";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import emailValidator from "email-validator";
import axios from "axios";
import urls from "../../constants/url.js";
import "./styles.css";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [mobile, setMobile] = useState("");
  const [text, setText] = useState("Submit");
  const loading = useRef(false);

  const submitQuery = useCallback(
    async (email, mobile, subject, content) => {
      if (!loading.current) {
        loading.current = true;
        const result = await postQuery(email, mobile, subject, content);
        setText(result);
        loading.current = false;
      }
    },
    [setText]
  );

  return (
    <React.Fragment>
      <div className="contact-container">
        <div className="contact-info-container">
          <Info id={0} title="Phone: +971-567100237" />
          <Info id={1} title="Email: karan.gupta@kpgaccountants.com" />
          <Info
            id={2}
            title="Office No. 15, 25th Floor, Prime Tower, Bussiness Bay, Dubai, UAE"
          />
        </div>
        <div className="contact-map">
          <iframe
            title="maps-frame"
            src={data_map_src}
            className="contact-map-iframe"
          ></iframe>
        </div>
      </div>
      <div className="contact-from">
        <h2 style={{ fontFamily: '"Krona One", sans-serif', padding: "20px" }}>
          Enquire And we'll be in touch.
        </h2>
        <TextField
          className="contact-input-fields"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
          style={{ margin: "10px" }}
        />
        <TextField
          className="contact-input-fields"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          label="Mobile"
          variant="outlined"
          style={{ margin: "10px" }}
        />
        <TextField
          className="contact-input-fields"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          label="Subject"
          variant="outlined"
          style={{ margin: "10px" }}
        />
        <TextField
          className="contact-input-fields"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          label="Content"
          variant="outlined"
          multiline
          rows={4}
          inputProps={{ maxLength: 254 }}
          style={{ margin: "10px" }}
        />
        <Button
          variant="contained"
          style={{
            margin: "20px",
            color: "#ffffff",
            backgroundColor: "#388e3c",
          }}
          onClick={() => submitQuery(email, mobile, subject, content)}
        >
          {text}
        </Button>
      </div>
    </React.Fragment>
  );
};

const icons = [
  <CallIcon style={{ fontSize: 30 }} />,
  <MailIcon style={{ fontSize: 30 }} />,
  <LocationIcon style={{ fontSize: 30 }} />,
];

const Info = ({ id, title }) => {
  return (
    <div className="contact-info">
      {icons[id]}
      <p style={{ paddingLeft: "50px" }}>{title}</p>
    </div>
  );
};

const data_map_src =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4391897082864!2d55.271516399999996!3d25.1884074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f697fa93efd81%3A0xeb55b89e0575e89a!2sKPG%20Accountants%20And%20Tax%20Consultants!5e0!3m2!1sen!2sin!4v1652716863147!5m2!1sen!2sin";

const postQuery = (email, mobile, subject, content) => {
  return new Promise((resolve, reject) => {
    if (!emailValidator.validate(email)) return resolve("Invalid Email");
    if (subject.length < 1) return resolve("Enter Subject");
    if (content.length < 1) return resolve("Enter Content");
    if (!/^\d{10}$/.test(mobile)) return resolve("Invalid Mobile");
    axios
      .post(urls.query, { email, mobile, subject, content })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) resolve(err.response.data);
        resolve("Network Error");
      });
  });
};

export default Contact;
