import React, { useEffect } from "react";
import AppBarComponent from "../components/app-bar/app_bar";
import AdminComponent from "../components/admin/admin";
import FooterLinksComponent from "../components/footer/footer";
import DevBannerComponent from "../components/dev-banner/banner";

const Admin = () => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <AppBarComponent />
      <AdminComponent />
      <FooterLinksComponent />
      <DevBannerComponent />
    </div>
  );
};

export default Admin;
