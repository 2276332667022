import React from "react";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import LocationIcon from "@material-ui/icons/LocationOn";
import "./styles.css";

const InfoBar = () => {
  return (
    <div className="info-bar-container">
      <div className="info-bar-tagline">
        <p className="info-bar-p" style={{ fontSize: "medium" }}>
          KPG Accountants & Tax Consultants
        </p>
      </div>
      <div className="info-bar-about-container">
        <Info id={0} title="+971-567100237" />
        <Info id={1} title="Business Bay, Dubai" />
        <Info id={2} title="karan.gupta@kpgaccountants.com" />
      </div>
    </div>
  );
};

const icons = [
  <CallIcon style={{ color: "#ffffff" }} />,
  <LocationIcon style={{ color: "#ffffff" }} />,
  <MailIcon style={{ color: "#ffffff" }} />,
];

const Info = ({ id, title }) => {
  return (
    <div className="info-bar-about">
      {icons[id]}
      <p className="info-bar-p">{title}</p>
    </div>
  );
};

export default InfoBar;
