export const data_content_head_text = {
  heading: "KPG Accountants & Tax Consultants",
  body1:
    "KPG Accountants & Tax Consultants is a U.A.E. registered accounting firm providing cost-effective value-added solutions to meet all your business needs. We believe that our experience can help your business achieve success, that success is what we strive for allowing us to grow together. Currently, the business environment has become more challenging and competitive and organizations looking an accounting firm who will help them in every aspect of their business. Our team brings extensive experience and commitment to deliver these services with the highest level of professional integrity.",
  body2:
    "Our reputation is grounded in honesty, trust, quality, efficiency and cost-effectiveness. We believe in serving our clients with energy, enthusiasm and vision. Our professional approach combined with a commitment to personal service ensures fulfilment of our client's requirements.",
};

export const data_content_body_text = {
  heading: "Looking for Accountancy & Tax Consultation",
  body1:
    "KPG Accountants & Tax Consultants focused on providing entrepreneurial business of varying sizes with timely, proactive and customized business solutions from startup and early development to the latest stages of a business lifecycle.  We are providing weekly, monthly and quarterly part-time accounting, accounting supervision services by qualified and professional accountants. Our professionals take a risk based approach to internal audit to help clients improve performance and operating efficiency. We go beyond traditional fundamentals and add tangible value to elevate internal audit to an even more strategic and productive role in today’s corporate governance environment.A successful business can not operate and create profit without financial management.",
  body2:
    "Every business or group business need to maintain their cash flow perfectly for future uncertain risk and liabilities.Our firm is helping clients to manage finance for minimizing business risk and save unnecessary finance cost. We are also helping to get bank loans, trade facilities, project finance and bank guarantee. Our firm has knowledge and resources to advise you and your business on the most appropriate method of finance for you.",
};

export const data_foot_cards = {
  0: "With no hidden cost, you will find low cost",
  1: "Fast growing community of professionals",
  2: "You get to speak to Professionals",
  3: "Focused and Determined audience",
};

export const data_accordian_sol =
  "Hiring a chartered accountant is important because he is a compliance expert and to effectively and smoothly run your business one must follow all the rules and regulations and law of the land. Hiring a chartered accountant will give you and your business an extra edge over your competitors. One must hire a chartered accountant";
