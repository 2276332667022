import React from "react";

const Financial = () => {
  return (
    <div>
      <h1>Internal Audit</h1>
      <p>
        Assessing and managing risk is an everyday task that needs to be met
        head-on. Companies should develop and implement a plan that follows a
        top-down, risk-based approach to identify and manage potential risks.
        Our internal audit services are designed to be right-sized to meet the
        needs of your organization, whether you need assistance for your
        existing internal audit function, or a turn-key solution.
      </p>
      <p>
        With internal strategies tailored to the individual needs of each
        client, KPG Accountants  conducts audits in a pro, forward-thinking manner
        – not as a traditional advisory service, but as a tool for future
        success. An audit allows businesses to identify and evaluate their
        internal controls and better understand what actions need to be taken to
        ensure operations are running efficiently at all times.
      </p>
      <p>As part of an internal audit, clients are able to:</p>
      <ul>
        <li>Identify and Manage risk</li>
        <li>Add value and efficiency to operations</li>
        <li>Develop successful strategies</li>
        <li>Make smarter business decisions</li>
        <li>Safeguard assets</li>
      </ul>
    </div>
  );
};

export default Financial;
