import React from "react";

const VAT = () => {
  return (
    <div>
      <h1>VAT and Tax Advisory</h1>
      <p>
        Being a registered tax agent with Federal Tax Authority we provide VAT
        (Value added Tax) Advisory Services in UAE providing clients with a wide
        range of Tax Services in UAE with accordance to the client’s business
        needs. VAT Experts at KPG Accountants are committed to provide the best
        VAT Advisory Service in the UAE to the clients in compliance with the
        rules and regulations of VAT in the UAE having their international
        experience in Taxation. The Tax Team at KPG Accountants are
        professionally equipped with relevant industry experience from different
        ends of the globe.
      </p>
      <p>VAT SERVICES includes:</p>
      <ul>
        <li>
          Representation to FTA on behalf of client as registered tax agent.
        </li>
        <li>Registration for VAT with Federal Tax Authority (FTA).</li>
        <li>Implementation of VAT in the organization.</li>
        <li>Review of accounting transactions to be compliant with VAT.</li>
        <li>Filing of monthly/ quarterly and yearly VAT returns.</li>
        <li>Applying for VAT refund return forms.</li>
        <li>Awareness session to a team of people.</li>
      </ul>
      <p>
        As per the article 14 of the federal law no 7 of 2017 and article 10 on
        the executive regulations specifies the procedure for listing a Tax
        agent in the register and the rights and obligations of the tax agent
        before the authority and the taxable person. KPG Accountants is one of
        the registered tax agent in the UAE to serve the business community
        better. Below is the screen shot from the official website of the FTA
        showing our name as the registered tax agent in the list.
      </p>
    </div>
  );
};

export default VAT;
