import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import StatutoryCompo from "./components/statutory";
import VATCompo from "./components/vat";
import BussinessCompo from "./components/bussiness";
import InternalCompo from "./components/internal";
import PayrollCompo from "./components/payroll";
import AccountingCompo from "./components/accounting";
import "./styles.css";

const comps = [
  <AccountingCompo />,
  <VATCompo />,
  <BussinessCompo />,
  <InternalCompo />,
  <PayrollCompo />,
  <StatutoryCompo />,
];

const Services = ({ id }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const setId = (id) => history.push(pathname.replace(/.$/, id));
  return (
    <div className="services-container">
      <div className="services-bar-wrapper">
        <Paper elevation={5} className="services-bar">
          <Title
            id={0}
            active={id}
            click={setId}
            title="Accounts & Bookkeeping"
          />
          <Title id={1} active={id} click={setId} title="VAT Consultancy" />
          <Title id={2} active={id} click={setId} title="Business Setup" />
          <Title id={3} active={id} click={setId} title="Internal Audit" />
          <Title id={4} active={id} click={setId} title="Payroll Management" />
          <Title id={5} active={id} click={setId} title="Statutory Audit" />
        </Paper>
      </div>
      <div className="service-details">{comps[id]}</div>
    </div>
  );
};

const Title = ({ title, id, click, active }) => {
  const style = { transform: "scale(1.1)" };
  return (
    <p
      className="services-bar-titles"
      style={id === parseInt(active) ? style : {}}
      onClick={() => click(id)}
    >
      {title}
    </p>
  );
};

export default Services;
