import React from "react";
import WorldImage from "../../kpg-accountants-assets/kpg-accountants-world-map.jpg";
import Logo from "../../kpg-accountants-assets/kpg-accountants-logo.jpg";
import FbIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import InstaIcon from "@material-ui/icons/Instagram";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import LocationIcon from "@material-ui/icons/LocationOn";
import "./styles.css";
import { data_footer_about, data_footer_links } from "./data";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div
      className="footer-container"
      style={{
        backgroundImage: `url(${WorldImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="footer-about-card">
        <div className="footer-about-card-firm">
          <img
            src={Logo}
            alt="kpg-accountants-foot-logo"
            title="kpg-accountants-foot-logo"
            className="footer-about-card-firm-image"
          />
          <p style={{ color: "#ffffff", textAlign: "justify" }}>
            {data_footer_about.summary}
          </p>
          <CardFirmInfo icon={0} text={data_footer_about.call} />
          <CardFirmInfo icon={1} text={data_footer_about.address} />
          <CardFirmInfo icon={2} text={data_footer_about.email} />
        </div>
        <div className="footer-about-card-social">
          <FbIcon style={{ color: "#ffffff", cursor: "pointer" }} />
          <TwitterIcon style={{ color: "#ffffff", cursor: "pointer" }} />
          <LinkedinIcon style={{ color: "#ffffff", cursor: "pointer" }} />
          <InstaIcon style={{ color: "#ffffff", cursor: "pointer" }} />
        </div>
      </div>
      <div className="footer-contact-map">
        <iframe
          title="maps-frame"
          src={data_map_src}
          className="footer-contact-map-iframe"
        ></iframe>
      </div>
      <Card
        title={data_footer_links[0].title}
        links={data_footer_links[0].links}
      />
      {/* <Card
        title={data_footer_links[1].title}
        links={data_footer_links[1].links}
      /> */}
    </div>
  );
};

const data_map_src =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4391897082864!2d55.271516399999996!3d25.1884074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f697fa93efd81%3A0xeb55b89e0575e89a!2sKPG%20Accountants%20And%20Tax%20Consultants!5e0!3m2!1sen!2sin!4v1652716863147!5m2!1sen!2sin";

const Card = ({ title, links }) => {
  return (
    <div className="footer-link-card">
      <h3>{title}</h3>
      {links.map((l, index) =>
        l.link[0] === "/" ? (
          <Link
            key={"footer-link-card" + index}
            to={l.link}
            style={{ textDecoration: "none" }}
          >
            <p>{l.label}</p>
          </Link>
        ) : (
          <a
            key={"footer-link-card" + index}
            href={l.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <p key={"footer-link-card" + index}>{l.label}</p>
          </a>
        )
      )}
    </div>
  );
};

const icons = [<CallIcon />, <LocationIcon />, <MailIcon />];

const CardFirmInfo = ({ icon, text }) => {
  return (
    <div className="footer-about-card-firm-text">
      {icons[icon]}
      <p style={{ marginLeft: "15px", textAlign: "justify" }}>{text}</p>
    </div>
  );
};

export default Footer;
