import React from "react";
import Paper from "@material-ui/core/Paper";
// import { data_team } from "./data";
import "./styles.css";

const Team = () => {
  return (
    <div className="team-container">
      {/* <Card
        heading={data_team[0].heading}
        title={data_team[0].title}
        description={data_team[0].description}
        url={data_team[0].url}
      />
      <Card
        heading={data_team[1].heading}
        title={data_team[1].title}
        description={data_team[1].description}
        url={data_team[1].url}
      /> */}
      <h1 className="team-card-temp-heading">
        Coming together is the beginning. Staying together is progress. Working
        together is success.
      </h1>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const Card = ({ heading, title, description, url }) => {
  return (
    <Paper elevation={5} className="team-card">
      <div className="team-card-image">
        <img
          src={url}
          alt="kpg-accountants-team-member"
          title="kpg-accountants-team-member"
          height="250px"
        />
      </div>
      <div className="team-card-details">
        <h2 className="team-card-details-heading">{heading}</h2>
        <p className="team-card-details-title">{title}</p>
        <hr></hr>
        <p className="team-card-details-para">{description}</p>
      </div>
    </Paper>
  );
};

export default Team;
