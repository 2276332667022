import React from "react";
import "./styles.css";

const Banner = () => {
  return (
    <div className="dev-banner">
      <p className="dev-banner-slogan">
        Developed and Maintained by&nbsp;
        <span style={{ fontWeight: "bold" }}>
          <a
            href="https://github.com/rajataggarwal2019"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#616161" }}
          >
            Code Green 2020.
          </a>
        </span>
      </p>
    </div>
  );
};

export default Banner;
