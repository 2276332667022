import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import ServiceIcon from "@material-ui/icons/SettingsApplications";
import TeamIcon from "@material-ui/icons/Group";
import ContactIcon from "@material-ui/icons/Call";
import DownArrow from "@material-ui/icons/ArrowDropDown";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./styles.css";
import Logo from "../../kpg-accountants-assets/kpg-accountants-logo.jpg";

const AppBar = () => {
  return (
    <div>
      <div className="app-bar-container">
        <div className="app-bar-logo">
          <img
            src={Logo}
            alt="kpg-accountants-logo"
            title="kpg-accountants-logo"
            style={{ height: "75%" }}
          />
        </div>
        <NavBar />
      </div>
      <div className="app-bar-spacer">&nbsp;</div>
    </div>
  );
};

const LinkWrapper = ({ link, title }) => {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <p className="app-bar-links">{title}</p>
    </Link>
  );
};

const NavBar = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const resize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  if (width >= 768)
    return (
      <div className="app-bar-links-container">
        <LinkWrapper title="Home" link="/" />
        <LinkWrapper title="Services" link="/services" />
        <LinkWrapper title="Team" link="/team" />
        <LinkWrapper title="Contact" link="/contact" />
      </div>
    );

  return <NavDrawer />;
};

const NavDrawer = () => {
  const [open, setOpen] = useState(false);
  const [drop, setDrop] = useState(false);

  return (
    <div className="app-bar-links-container">
      <IconButton onClick={() => setOpen(!open)}>
        <MenuIcon style={{ fontSize: 35, color: "#ffffff" }} />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={() => setOpen(!open)}>
        <List style={{ width: 250, padding: 0 }}>
          <ListItem id="app-bar-nav-top-container">
            <IconButton style={{ padding: 0 }} onClick={() => setOpen(!open)}>
              <MenuIcon style={{ fontSize: 35, color: "#ffffff" }} />
            </IconButton>
          </ListItem>
          <NavDrawerListItem id={0} label="Home" link="/home" />
          <ServiceMenu
            open={drop}
            click={() => setDrop(!drop)}
            superClick={() => setOpen(!open)}
          />
          <NavDrawerListItem id={2} label="Team" link="/team" />
          <NavDrawerListItem id={3} label="Contact" link="/contact" />
        </List>
      </Drawer>
    </div>
  );
};

const serviceList = [
  "Accounts & Bookkeeping",
  "VAT Consultancy",
  "Business Setup",
  "Internal Audit",
  "Payroll Management",
  "Statutory Audit",
];

const ServiceMenu = ({ open, click, superClick }) => {
  return (
    <React.Fragment>
      <ListItem button onClick={click}>
        <ListItemIcon>{Icon[1]}</ListItemIcon>
        <ListItemText
          primary="Services"
          style={{ textDecoration: "none", color: "#000000" }}
        />
        <ListItemIcon style={{ paddingRight: "40px" }}>
          <DownArrow style={{ fontSize: 20 }} />
        </ListItemIcon>
      </ListItem>
      {open
        ? serviceList.map((label, index) => (
            <NavDrawerSubListItem
              key={"service-sub-list" + index}
              label={label}
              link={`/services/${index}`}
              click={superClick}
            />
          ))
        : null}
    </React.Fragment>
  );
};

const Icon = {
  0: <HomeIcon />,
  1: <ServiceIcon />,
  2: <TeamIcon />,
  3: <ContactIcon />,
};

const NavDrawerListItem = ({ id, label, link }) => {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <ListItem button>
        <ListItemIcon>{Icon[id]}</ListItemIcon>
        <ListItemText
          primary={label}
          style={{ textDecoration: "none", color: "#000000" }}
        />
      </ListItem>
    </Link>
  );
};

const NavDrawerSubListItem = ({ label, link, click }) => {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <ListItem button>
        <ListItemIcon></ListItemIcon>
        <ListItemText
          primary={label}
          style={{ textDecoration: "none", color: "#000000" }}
          onClick={click}
        />
      </ListItem>
    </Link>
  );
};

export default AppBar;
