import React, { useState, useCallback, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Login from "./login";
import urls from "../../constants/url.js";
import "./styles.css";

const Admin = () => {
  const [token, setToken] = useState(null);
  const [tab, setTab] = useState(1);

  if (token == null) return <Login click={setToken} />;
  return (
    <div className="admin-container">
      <h1>Admin Panel</h1>
      <AppBar
        position="static"
        style={{ zIndex: 0, margin: "10px", paddingLeft: "20px" }}
      >
        <Tabs value={tab} onChange={(e, t) => setTab(t)}>
          <Tab label="Active" />
          <Tab label="Inactive" />
          {/* <Tab label="All" /> */}
        </Tabs>
      </AppBar>
      {tab === 0 ? <ActiveOnly token={token} /> : null}
      {tab === 1 ? <InactiveAll token={token} url={urls.inactive} /> : null}
      {/* {tab === 2 ? <InactiveAll token={token} url={urls.all} /> : null} */}
    </div>
  );
};

const ActiveOnly = ({ token }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    query(token, urls.active).then(setData);
  }, [token, setData]);

  return (
    <React.Fragment>
      {data.map((e) => (
        <ActiveCard e={e} token={token} />
      ))}
    </React.Fragment>
  );
};

const InactiveAll = ({ token, url }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    query(token, url).then(setData);
  }, [token, setData, url]);

  return (
    <React.Fragment>
      {data.map((e) => (
        <Paper className="admin-query" elevation={5}>
          <h4>Email: {e.email}</h4>
          <h4>Mobile: {e.mobile}</h4>
          <h4>Subject: {e.subject}</h4>
          <p>Content: {e.content}</p>
          <p style={{ fontSize: "smaller" }}>Received at: {e.date}</p>
        </Paper>
      ))}
    </React.Fragment>
  );
};

const ActiveCard = ({ e, token }) => {
  const [text, setText] = useState("Mark As Done");

  const submitQuery = useCallback(
    async (id) => {
      const result = await markQuery(id, token);
      setText(result);
    },
    [setText, token]
  );

  return (
    <Paper className="admin-query" elevation={5}>
      <h4>Email: {e.email}</h4>
      <h4>Mobile: {e.mobile}</h4>
      <h4>Subject: {e.subject}</h4>
      <p>Content: {e.content}</p>
      <p style={{ fontSize: "smaller" }}>Received at: {e.date}</p>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "20px" }}
        onClick={() => submitQuery(e.id)}
      >
        {text}
      </Button>
    </Paper>
  );
};

const query = (token, url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) resolve(err.response.data);
        resolve("Network Error");
      });
  });
};

const markQuery = (id, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urls.mark,
        { id },
        { headers: { authorization: `Bearer ${token}` } }
      )
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err.response) resolve(err.response.data);
        resolve("Network Error");
      });
  });
};

export default Admin;
